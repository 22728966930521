import React, { useContext } from 'react'
import LazyLoadBgImage from '../LazyLoadBgImage'
import { LinkRenderer } from '../../../lib/utils'
import Carousel from '../CityGuide/Carousel'
import { sContext } from '../../../context'
import useIsMobileView from '../../../hooks/useIsMobileView'
import ReactMarkdown from '../../../lib/ReactMarkdown'

function scrollRight() {
  var leftPos = document.querySelector('.hotels-container').scrollLeft
  var container = document.querySelector('.hotels-container')
  container.scroll({
    left: leftPos + 600,
    top: 0,
    behavior: 'smooth'
  })
  document.getElementById('left').style.display = 'flex'
}

function scrollLeft() {
  var leftPos = document.querySelector('.hotels-container').scrollLeft
  var container = document.querySelector('.hotels-container')
  container.scroll({
    left: leftPos - 600,
    top: 0,
    behavior: 'smooth'
  })
  document.getElementById('right').style.display = 'flex'
}

const HotelsCarousel = element => {
  const localePath = useContext(sContext).currentLocale
  const isMobile = useIsMobileView();

  return (
    <div
      id="title_2"
      className="row hotels-main"
      data-title={element.text} >
      <div className="col-lg-12">
        <div className="row hotels-line">
          <div className="col-lg-1 hotels-line-left"></div>
          <div className="col-lg-1 hotels-line-right"></div>
        </div>
        <h2 className="row hotels-title">
          {' '}
          <ReactMarkdown
            source={element.text}
            escapeHtml={false}
            renderers={{ link: LinkRenderer }}
          />{' '}
        </h2>
        <div className="hotels-wrapper">
          {isMobile ? (
            <div className="hotels-mobile-carousel">
              <Carousel items={element.subSections} />
            </div>
          ) : (
            <div className="hotels-container">
              <ul className="row hotels-guides">
                {element.subSections &&
                  element.subSections.map((data, i) => {
                    const articleId = `Hotels-${i}`
                    const innerHtml = 
                    data.fields.banner &&
                    data.fields.title &&
                    data.fields.subtitle && (
                      <React.Fragment key={`city-guide-carousel-1-${i}`} >
                        <LazyLoadBgImage
                          asset={
                            data.fields.overrideBanner
                              ? data.fields.overrideBanner.fields
                              : data.fields.banner.fields
                          }
                          className="hotels-image" />
                        <div
                          id={articleId}
                          className="hotels-header" >
                          {data.fields.title}
                        </div>
                        <div className="hotels-sub">
                          {data.fields.subtitle}
                        </div>
                      </React.Fragment>
                    )
                    if (data.fields && data.fields.referencedContent && data.fields.referencedContent.fields && data.fields.referencedContent.fields.path) {
                      let formatedPath
                      const path = data.fields.referencedContent.fields.path
                      const category = data.fields.referencedContent.fields.category 
                      if(category) {
                        formatedPath = `${category.fields.path}/${path}`
                      } else {
                        formatedPath = `article/${path}`
                      }
                      return innerHtml ? (
                        <li key={`city-guide-carousel-2-${i}`}>
                          <article aria-labelledby={articleId}>
                            <a
                              className="hotels-data"
                              href={`/${localePath}/${formatedPath}/`} >
                              {innerHtml}
                            </a>
                          </article>
                        </li>
                      ) : null
                    }
                    return innerHtml ? (
                      <li key={`city-guide-carousel-3-${i}`}>
                        <article
                          className="hotels-data"
                          aria-labelledby={articleId} >
                          {innerHtml}
                        </article>
                      </li>
                    ) :null
                  })}
                <div className="hotels-overlay" id="right">
                  <div className="hotels-next" onClick={scrollRight}></div>
                </div>
                <div className="hotels-overlay" id="left">
                  <div className="hotels-next" onClick={scrollLeft}></div>
                </div>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default HotelsCarousel
