import React from 'react'
import MapModule from './MapModule'
import { sContext } from '../../../context'

class MultipleEntry extends React.Component {
  localePath = this.context.currentLocale

  state = {
    style: {
      height: '750px',
      margin: '0 -15px'
    },
    directions: ''
  }

  render() {
    if (!this.props.subSections.length) {
      return null
    } else {
      return (
        <div
          className="row multipleentry-main"
          style={this.state.style}
        >
          <MapModule
            mapColour={this.props.subSections[0].fields.mapColour}
            mapMarkers={this.props.markers}
            mapZoom={this.props.subSections[0].fields.mapZoom}
            mapCenter={this.props.subSections[0].fields.mapCenter}
          />
          <div className="col-lg-12 multipleentry-overlay">
            <div className="col-lg-1"></div>
            <div className="col-lg-1"></div>
            {this.localePath === '/en'
              ? 'Everything on a Map'
              : 'tout sur une carte'}
            <div className="multipleentry-links">
              <a
                target="_blank"
                rel="noopener noreferrer"
                style={this.state.directions === '' ? { display: 'none' } : {}}
                href={
                  this.state.directions === ''
                    ? undefined
                    : this.state.directions
                }
              >
                Directions <div className="dir"></div>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className={this.localePath === '/en' ? '' : 'fr'}
                style={
                  this.state.directions === '' ? { marginTop: '23px' } : {}
                }
                href={this.props.text}
              >
                {this.localePath === '/en' ? 'Download' : 'Télécharger'}
                <div className="dl"></div>
              </a>
            </div>
          </div>
        </div>
      )
    }
  }
}

MultipleEntry.contextType = sContext

export default MultipleEntry