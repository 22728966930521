import React, { useState, useEffect } from 'react'

let scrollPositions

const StickyNav = ({
  externalLinks,
  triggerMore
}) => {
  const [items, setItems] = useState([])
  const [current, setCurrent] = useState(null)

  const scrollTo = (id) => {
    if(typeof window !== 'undefined') {
      const navHeight = document.querySelector('.globalnav-main').offsetHeight
      window.scrollTo(0, (scrollPositions[id] - navHeight))
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setItems([])
      triggerScroll()
    }, 1000)
  }, [triggerMore])

  const triggerScroll = () => {
    if(!window || window.innerWidth <= 991) { return }
    let newItems = false
    let itemsPrep = []

    if(!items.length) {
      let skipSetItems
      scrollPositions = {}

      for (let i = 0; i < 5; i++) {
        const id = `title_${i + 1}`
        const element = document.querySelector(`#${id}`)

        if(element) {
          const elementTop = element.offsetTop

          if(!elementTop || elementTop <= 0) {
            skipSetItems = true
            break;
          }

          scrollPositions[id] = elementTop

          let title = element.dataset.title

          if(title ===  ' eat &amp; drink ') {
            title = `eat & drink`
          } else if(title === ' <p>Where to stay</p> ') {
            title = 'Where to stay'
          }

          itemsPrep.push({
            id,
            title
          })
        }
      }

      newItems = itemsPrep.length
      if(newItems && !skipSetItems) {
        setItems(itemsPrep)
      }

    } else {
      let newCurrent
      const topOffset = window.scrollY + (window.innerHeight * 0.35)
      
      if(
        topOffset < scrollPositions.title_1 ||
        scrollPositions.title_5 < topOffset + 125
      ) {
        newCurrent = null

      } else if(
        scrollPositions.title_1 <= topOffset &&
        topOffset < scrollPositions.title_2
      ) {
        newCurrent = 'title_1'

      } else if(
        scrollPositions.title_2 <= topOffset &&
        topOffset < scrollPositions.title_3
      ) {
        newCurrent = 'title_2'

      } else if(
        scrollPositions.title_3 <= topOffset &&
        topOffset < scrollPositions.title_4
      ) {
        newCurrent = 'title_3'

      } else if(
        scrollPositions.title_4 <= topOffset && 
        topOffset < scrollPositions.title_5
      ) {
        newCurrent = 'title_4'
        
      } else if(
        scrollPositions.title_5 <= topOffset &&
        topOffset < (scrollPositions.title_5 - 130)
      ) {
        newCurrent = 'title_5'
      }

      if(current !== newCurrent) {
        setCurrent(newCurrent)
      }
    }
  }

  useEffect(() => {
    triggerScroll()
    if(typeof window !== 'undefined') {
      window.addEventListener('scroll', triggerScroll)
    }

    return () => {
      if(typeof window !== 'undefined') {
        window.removeEventListener('scroll', triggerScroll)
      }
    }
  }, [triggerScroll])

  return (
    <>
      {items.length && current ? (
        <div className="stickynav-main">
          <div
            id="stickyNav"
            className="stickynav-internal" >
              {items.map(({
                id,
                title
              }) => (
                <div
                  key={`hotels-nav-${id}`}
                  className={`stickynav-link ${current === id ? 'is-current' : ''}`}
                  onClick={() => scrollTo(id)} >
                  {title}
                </div>
              ))}
            </div>
          <div className="stickynav-external">
            {externalLinks &&
              externalLinks[0].fields.links.map((data, i) => (
                <div
                  key={`external-link-${i}`}
                  className="stickynav-link" >
                  {' '}
                  <a href={data.fields.url}> {data.fields.text} </a>
                  {' '}
                </div>
              ))}
          </div>
        </div> 
      ) : null}
    </>
  )
}

export default StickyNav
