import React from 'react'
import LazyLoadBgImage from '../LazyLoadBgImage'
import { Fade } from './CityGuidePoster'

const TopCityGuide = ({
  imageTitle: title,
  inArticleAsset: {
    fields: image
  },
  cityGuidesCode
}) => {
  return (
    <LazyLoadBgImage
      className={`
        row
        poster-main
      `}
      asset={image || {}}
      overrides={{
        w: 1400,
        q: 70
      }}
      gradient='linear' >
      <div className="col-lg-12">
        <h1 className="row poster-city">
          { title }
        </h1>
        <div className="row poster-airport">
          {typeof window !== 'undefined' ? (
            <Fade>
              {cityGuidesCode.map((code, i) => (
                <p key={i + code} className="poster-airport-code">
                  {code}
                </p>
              ))}
            </Fade>
          ) : (
            <>
              {cityGuidesCode.filter((_, i) => i === 0).map((code, i) => (
                <p key={i + code} className="poster-airport-code">
                  {code}
                </p>
              ))}
            </>
          )}
        </div>
        <div className="row poster-lines">
          <div className="col-lg-1 poster-lines-left"></div>
          <div className="col-lg-1 poster-lines-right"></div>
        </div>
      </div>
    </LazyLoadBgImage>
  )
}

export default TopCityGuide
