var currencyNamesFR = [
  {
    Pays: 'AFGHANISTAN',
    Devise: 'Afghani',
    Code: 'AFN',
    Numéro: 971
  },
  {
    Pays: 'AFRIQUE DU SUD',
    Devise: 'Rand',
    Code: 'ZAR',
    Numéro: 710
  },
  {
    Pays: 'ALBANIE',
    Devise: 'Lek',
    Code: 'ALL',
    Numéro: 8
  },
  {
    Pays: 'ALGERIE',
    Devise: 'Dinars algériens',
    Code: 'DZD',
    Numéro: 12
  },
  {
    Pays: 'ALLEMAGNE',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'ANDORRE',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'ANGOLA',
    Devise: 'Kwanza',
    Code: 'AOA',
    Numéro: 973
  },
  {
    Pays: 'ANGUILLA',
    Devise: 'Dollar des Caraïbes orientales',
    Code: 'XCD',
    Numéro: 951
  },
  {
    Pays: 'ANTARCTIQUE',
    Devise: 'Pas de devise universelle',
    Code: '',
    Numéro: null
  },
  {
    Pays: 'ANTIGUA-ET-BARBUDA',
    Devise: 'Dollar des Caraïbes orientales',
    Code: 'XCD',
    Numéro: 951
  },
  {
    Pays: 'ARABIE SAOUDITE',
    Devise: 'Riyal Saoudiens',
    Code: 'SAR',
    Numéro: 682
  },
  {
    Pays: 'ARGENTINE',
    Devise: 'Peso Argentin',
    Code: 'ARS',
    Numéro: 32
  },
  {
    Pays: 'ARMENIE',
    Devise: 'Dram Armenien',
    Code: 'AMD',
    Numéro: 51
  },
  {
    Pays: 'ARUBA',
    Devise: 'Aruban Florin',
    Code: 'AWG',
    Numéro: 533
  },
  {
    Pays: 'AUSTRALIE',
    Devise: 'Dollar australien',
    Code: 'AUD',
    Numéro: 36
  },
  {
    Pays: 'AUTRICHE',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'AZERBAIJAN',
    Devise: 'Azerbaijanian Manat',
    Code: 'AZN',
    Numéro: 944
  },
  {
    Pays: 'BAHAMAS (LES)',
    Devise: 'Dollar Bahaméen',
    Code: 'BSD',
    Numéro: 44
  },
  {
    Pays: 'BAHRAIN',
    Devise: 'Dinar Bahraini',
    Code: 'BHD',
    Numéro: 48
  },
  {
    Pays: 'BANGLADESH',
    Devise: 'Taka',
    Code: 'BDT',
    Numéro: 50
  },
  {
    Pays: 'BARBADOS',
    Devise: 'Dollars Barbados',
    Code: 'BBD',
    Numéro: 52
  },
  {
    Pays: 'BELGIQUE',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'BELIZE',
    Devise: 'Dollar de Bélize',
    Code: 'BZD',
    Numéro: 84
  },
  {
    Pays: 'BENIN',
    Devise: 'CFA Franc BCEAO',
    Code: 'XOF',
    Numéro: 952
  },
  {
    Pays: 'BERMUDES',
    Devise: 'Dollar Bermudien',
    Code: 'BMD',
    Numéro: 60
  },
  {
    Pays: 'BHUTAN',
    Devise: 'Ngultrum',
    Code: 'BTN',
    Numéro: 64
  },
  {
    Pays: 'BHUTAN',
    Devise: 'Rupee Indienne',
    Code: 'INR',
    Numéro: 356
  },
  {
    Pays: 'BIÉLORUSSIE',
    Devise: 'Ruble Biélorusse',
    Code: 'BYR',
    Numéro: 974
  },
  {
    Pays: 'BOLIVIE (ÉTAT PLURINATIONAL DE )',
    Devise: 'Mvdol',
    Code: 'BOV',
    Numéro: 984
  },
  {
    Pays: 'BOLIVIE (ÉTAT PLURINATIONAL DE)',
    Devise: 'Boliviano',
    Code: 'BOB',
    Numéro: 68
  },
  {
    Pays: 'BOSNIE-HERZEGOVINE',
    Devise: 'Mark Convertible',
    Code: 'BAM',
    Numéro: 977
  },
  {
    Pays: 'BOTSWANA',
    Devise: 'Pula',
    Code: 'BWP',
    Numéro: 72
  },
  {
    Pays: 'BOUVET ILE',
    Devise: 'Couronne Norvégienne',
    Code: 'NOK',
    Numéro: 578
  },
  {
    Pays: 'BRUNEI DARUSSALAM',
    Devise: 'Brunei Dollar',
    Code: 'BND',
    Numéro: 96
  },
  {
    Pays: 'BRÉSIL',
    Devise: 'Real Brésilien',
    Code: 'BRL',
    Numéro: 986
  },
  {
    Pays: 'BULGARIE',
    Devise: 'Lev Bulgare',
    Code: 'BGN',
    Numéro: 975
  },
  {
    Pays: 'BURKINA FASO',
    Devise: 'Franc CFA BCEAO',
    Code: 'XOF',
    Numéro: 952
  },
  {
    Pays: 'BURUNDI',
    Devise: 'Franc Burundi',
    Code: 'BIF',
    Numéro: 108
  },
  {
    Pays: 'CABO VERDE',
    Devise: 'Cabo Verde Escudo',
    Code: 'CVE',
    Numéro: 132
  },
  {
    Pays: 'CAMBODIA',
    Devise: 'Riel',
    Code: 'KHR',
    Numéro: 116
  },
  {
    Pays: 'CAMEROUNE',
    Devise: 'Franc CFA BEAC',
    Code: 'XAF',
    Numéro: 950
  },
  {
    Pays: 'CANADA',
    Devise: 'Dollar Canadien',
    Code: 'CAD',
    Numéro: 124
  },
  {
    Pays: 'CAYMAN ISLANDS (THE)',
    Devise: 'Cayman Islands Dollar',
    Code: 'KYD',
    Numéro: 136
  },
  {
    Pays: 'CENTRAL AFRICAN REPUBLIC (THE)',
    Devise: 'CFA Franc BEAC',
    Code: 'XAF',
    Numéro: 950
  },
  {
    Pays: 'CHILE',
    Devise: 'Unidad de Fomento',
    Code: 'CLF',
    Numéro: 990
  },
  {
    Pays: 'CHILIE',
    Devise: 'Peso Chilien',
    Code: 'CLP',
    Numéro: 152
  },
  {
    Pays: 'CHINE',
    Devise: 'Yuan Renminbi',
    Code: 'CNY',
    Numéro: 156
  },
  {
    Pays: 'CHYPRE',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'COCOS (KEELING) ILES (LES)',
    Devise: 'Dollar Australien',
    Code: 'AUD',
    Numéro: 36
  },
  {
    Pays: 'COLOMBIE',
    Devise: 'Peso Colombien',
    Code: 'COP',
    Numéro: 170
  },
  {
    Pays: 'COLOMBIE',
    Devise: 'Unidad de Valor Real',
    Code: 'COU',
    Numéro: 970
  },
  {
    Pays: 'COMORES (LES)',
    Devise: 'Franc Comorien',
    Code: 'KMF',
    Numéro: 174
  },
  {
    Pays: 'CONGO (LA RÉPUBLIQUE DÉMOCRATIQUE DU)',
    Devise: 'Le Franc Congolais',
    Code: 'CDF',
    Numéro: 976
  },
  {
    Pays: 'CONGO (LE)',
    Devise: 'Franc CFA BEAC',
    Code: 'XAF',
    Numéro: 950
  },
  {
    Pays: 'COOK ILES (LES)',
    Devise: 'Dollar Néo-Zélandais',
    Code: 'NZD',
    Numéro: 554
  },
  {
    Pays: 'CORÉE (LA RÉPUBLIQUE DE)',
    Devise: 'Won',
    Code: 'KRW',
    Numéro: 410
  },
  {
    Pays: 'CORÉE (LA RÉPUBLIQUE DÉMOCRATIQUE DE )',
    Devise: 'Won Nord-coréen',
    Code: 'KPW',
    Numéro: 408
  },
  {
    Pays: 'COSTA RICA',
    Devise: 'Costa Rican Colon',
    Code: 'CRC',
    Numéro: 188
  },
  {
    Pays: 'CROATIE',
    Devise: 'Kuna',
    Code: 'HRK',
    Numéro: 191
  },
  {
    Pays: 'CUBA',
    Devise: 'Peso Convertible',
    Code: 'CUC',
    Numéro: 931
  },
  {
    Pays: 'CUBA',
    Devise: 'Peso Cubain',
    Code: 'CUP',
    Numéro: 192
  },
  {
    Pays: 'CURAÇAO',
    Devise: 'Florin des Antilles néerlandaises',
    Code: 'ANG',
    Numéro: 532
  },
  {
    Pays: "CÔTE D'IVOIRE",
    Devise: 'CFA Franc BCEAO',
    Code: 'XOF',
    Numéro: 952
  },
  {
    Pays: 'DANEMARK',
    Devise: 'Couronne Danoise',
    Code: 'DKK',
    Numéro: 208
  },
  {
    Pays: 'DJIBOUTI',
    Devise: 'Franc Djiboutien',
    Code: 'DJF',
    Numéro: 262
  },
  {
    Pays: 'DOMINIQUE',
    Devise: 'Dollar des Caraïbes orientales',
    Code: 'XCD',
    Numéro: 951
  },
  {
    Pays: 'EGYPTE',
    Devise: 'Pound Égyptien',
    Code: 'EGP',
    Numéro: 818
  },
  {
    Pays: 'EQUATEUR',
    Devise: 'Dollar ÉTATS-UNIS',
    Code: 'USD',
    Numéro: 840
  },
  {
    Pays: 'ERITHRÉE',
    Devise: 'Nakfa',
    Code: 'ERN',
    Numéro: 232
  },
  {
    Pays: 'ESPAGNE',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'ESTONIE',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'ETHIOPIE',
    Devise: 'Birr Éthiopienne',
    Code: 'ETB',
    Numéro: 230
  },
  {
    Pays: 'FALKLAND ILES (LES) [MALOUINES]',
    Devise: 'Livre des Îles Malouines',
    Code: 'FKP',
    Numéro: 238
  },
  {
    Pays: 'FIJI',
    Devise: 'Dollar des Fiji',
    Code: 'FJD',
    Numéro: 242
  },
  {
    Pays: 'FINLANDE',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'FOND MONÉTAIRE INTERNATIONAL (IMF) ',
    Devise: 'SDR (Droit de tirage spécial)',
    Code: 'XDR',
    Numéro: 960
  },
  {
    Pays: 'FRANCE',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'GABON',
    Devise: 'Franc CFA BEAC',
    Code: 'XAF',
    Numéro: 950
  },
  {
    Pays: 'GAMBIE (LA)',
    Devise: 'Dalasi',
    Code: 'GMD',
    Numéro: 270
  },
  {
    Pays: 'GEORGIE',
    Devise: 'Lari',
    Code: 'GEL',
    Numéro: 981
  },
  {
    Pays: 'GHANA',
    Devise: 'Cedi du Ghana',
    Code: 'GHS',
    Numéro: 936
  },
  {
    Pays: 'GIBRALTAR',
    Devise: 'Pound de Gibraltar',
    Code: 'GIP',
    Numéro: 292
  },
  {
    Pays: 'GRECE',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'GRENADE',
    Devise: 'Dollar des Caraïbes orientales',
    Code: 'XCD',
    Numéro: 951
  },
  {
    Pays: 'GROENLAND',
    Devise: 'Couronne Danoise',
    Code: 'DKK',
    Numéro: 208
  },
  {
    Pays: 'GUADELOUPE',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'GUAM',
    Devise: 'Dollar ÉTATS-UNIS',
    Code: 'USD',
    Numéro: 840
  },
  {
    Pays: 'GUATEMALA',
    Devise: 'Quetzal',
    Code: 'GTQ',
    Numéro: 320
  },
  {
    Pays: 'GUERNESEY',
    Devise: 'Livre Sterlling',
    Code: 'GBP',
    Numéro: 826
  },
  {
    Pays: 'GUINÉE',
    Devise: 'Franc Guinéen',
    Code: 'GNF',
    Numéro: 324
  },
  {
    Pays: 'GUINÉE ÉQUATORIALE',
    Devise: 'Franc CFA BEAC',
    Code: 'XAF',
    Numéro: 950
  },
  {
    Pays: 'GUINÉE-BISSAU',
    Devise: 'CFA Franc BCEAO',
    Code: 'XOF',
    Numéro: 952
  },
  {
    Pays: 'GUYANa',
    Devise: 'Dollar guyanien',
    Code: 'GYD',
    Numéro: 328
  },
  {
    Pays: 'GUYANE FRANÇAISE',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'GÉORGIE DU SUD ET LES ÎLES SANDWICH DU SUD',
    Devise: 'Pas de devise internationale',
    Code: '',
    Numéro: null
  },
  {
    Pays: 'HAITI',
    Devise: 'Gourde',
    Code: 'HTG',
    Numéro: 332
  },
  {
    Pays: 'HAITI',
    Devise: 'Dollar ÉTATS-UNIS',
    Code: 'USD',
    Numéro: 840
  },
  {
    Pays: 'HONDURAS',
    Devise: 'Lempira',
    Code: 'HNL',
    Numéro: 340
  },
  {
    Pays: 'HONG KONG',
    Devise: 'Dollar de Hong Kong',
    Code: 'HKD',
    Numéro: 344
  },
  {
    Pays: 'HONGRIE',
    Devise: 'Forint',
    Code: 'HUF',
    Numéro: 348
  },
  {
    Pays: 'ILE DE MAN',
    Devise: 'Livre Sterlling',
    Code: 'GBP',
    Numéro: 826
  },
  {
    Pays: 'ILE DU NORDFOLK',
    Devise: 'Dollar Australien',
    Code: 'AUD',
    Numéro: 36
  },
  {
    Pays: 'ILES FEROÉS (LES)',
    Devise: 'Couronne Danoise',
    Code: 'DKK',
    Numéro: 208
  },
  {
    Pays: 'ILES MARIANNE DU NORD (THE)',
    Devise: 'US Dollar',
    Code: 'USD',
    Numéro: 840
  },
  {
    Pays: 'ILES MARSHALL (LES)',
    Devise: 'Dollar ÉTATS-UNIS',
    Code: 'USD',
    Numéro: 840
  },
  {
    Pays: 'ILES VIERGES (BRITANIQUES)',
    Devise: 'Dollars ÉTATS-UNIS',
    Code: 'USD',
    Numéro: 840
  },
  {
    Pays: 'ILES Åland',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'INDE',
    Devise: 'Rupee Indienne',
    Code: 'INR',
    Numéro: 356
  },
  {
    Pays: 'INDONESIE',
    Devise: 'Rupiah',
    Code: 'IDR',
    Numéro: 360
  },
  {
    Pays: "IRAN (RÉPUBLIQUE ISLAMIQUE D')",
    Devise: 'Rial Iranien',
    Code: 'IRR',
    Numéro: 364
  },
  {
    Pays: 'IRAQ',
    Devise: 'Dinar Iraquien',
    Code: 'IQD',
    Numéro: 368
  },
  {
    Pays: 'IRLANDE',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'ISLANDE',
    Devise: 'Couronne Islandaise',
    Code: 'ISK',
    Numéro: 352
  },
  {
    Pays: 'ISRAEL',
    Devise: 'Nouveau Sheqel Israélien',
    Code: 'ILS',
    Numéro: 376
  },
  {
    Pays: 'ITALIE',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'JAMAIQUE',
    Devise: 'Dollars Jamaicain',
    Code: 'JMD',
    Numéro: 388
  },
  {
    Pays: 'JAPON',
    Devise: 'Yen',
    Code: 'JPY',
    Numéro: 392
  },
  {
    Pays: 'JERSEY',
    Devise: 'Livre Sterlling',
    Code: 'GBP',
    Numéro: 826
  },
  {
    Pays: 'JORDANIE',
    Devise: 'Dinars Jordanien',
    Code: 'JOD',
    Numéro: 400
  },
  {
    Pays: 'KAZAKHSTAN',
    Devise: 'Tenge',
    Code: 'KZT',
    Numéro: 398
  },
  {
    Pays: 'KENYA',
    Devise: 'Shilling Kenyan',
    Code: 'KES',
    Numéro: 404
  },
  {
    Pays: 'KIRGHIZISTAN',
    Devise: 'Som',
    Code: 'KGS',
    Numéro: 417
  },
  {
    Pays: 'KIRIBATI',
    Devise: 'Dollar Australien',
    Code: 'AUD',
    Numéro: 36
  },
  {
    Pays: 'KOWAIT',
    Devise: 'Dinar Kowaitien',
    Code: 'KWD',
    Numéro: 414
  },
  {
    Pays: 'LAO RÉPUBLIQUE DÉMOCRATIQUE POPULAIRE DU (LA)',
    Devise: 'Kip',
    Code: 'LAK',
    Numéro: 418
  },
  {
    Pays: 'LE SALVADOR',
    Devise: 'Le Colon Salvadorien',
    Code: 'SVC',
    Numéro: 222
  },
  {
    Pays: 'LE SALVADOR',
    Devise: 'Dollar ÉTATS-UNIS',
    Code: 'USD',
    Numéro: 840
  },
  {
    Pays: 'LESOTHO',
    Devise: 'Loti',
    Code: 'LSL',
    Numéro: 426
  },
  {
    Pays: 'LESOTHO',
    Devise: 'Rand',
    Code: 'ZAR',
    Numéro: 710
  },
  {
    Pays: 'LETTONIE',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'LIBAN',
    Devise: 'Pound Libanais',
    Code: 'LBP',
    Numéro: 422
  },
  {
    Pays: 'LIBERIA',
    Devise: 'Dollar du Liberia',
    Code: 'LRD',
    Numéro: 430
  },
  {
    Pays: 'LIBYE',
    Devise: 'Dinars Libien',
    Code: 'LYD',
    Numéro: 434
  },
  {
    Pays: 'LIECHTENSTEIN',
    Devise: 'Swiss Franc',
    Code: 'CHF',
    Numéro: 756
  },
  {
    Pays: 'LITHUANIE',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'LUXEMBOURG',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'MACAO',
    Devise: 'Pataca',
    Code: 'MOP',
    Numéro: 446
  },
  {
    Pays: 'MACEDONIE (ANCIENNE RÉPUBLIQUE YOUGOSLAVE DE)',
    Devise: 'Denar',
    Code: 'MKD',
    Numéro: 807
  },
  {
    Pays: 'MADAGASCAR',
    Devise: 'Malagasy Ariary',
    Code: 'MGA',
    Numéro: 969
  },
  {
    Pays: 'MALAWI',
    Devise: 'Kwacha',
    Code: 'MWK',
    Numéro: 454
  },
  {
    Pays: 'MALAYSIE',
    Devise: 'Ringgi Malaisien',
    Code: 'MYR',
    Numéro: 458
  },
  {
    Pays: 'MALDIVES',
    Devise: 'Rufiyaa',
    Code: 'MVR',
    Numéro: 462
  },
  {
    Pays: 'MALI',
    Devise: 'CFA Franc BCEAO',
    Code: 'XOF',
    Numéro: 952
  },
  {
    Pays: 'MALTE',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'MARTINIQUE',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'MAURICE',
    Devise: 'Mauritius Rupee',
    Code: 'MUR',
    Numéro: 480
  },
  {
    Pays: 'MAURITANIE',
    Devise: 'Ouguiya',
    Code: 'MRO',
    Numéro: 478
  },
  {
    Pays: 'MAYOTTE',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'MEXIQUE',
    Devise: 'Peso Mexicain',
    Code: 'MXN',
    Numéro: 484
  },
  {
    Pays: 'MEXIQUE',
    Devise: 'Mexican Unidad de Inversion (UDI)',
    Code: 'MXV',
    Numéro: 979
  },
  {
    Pays: 'MICRONESIE (ÉTATS FÉDÉRÉS DE)',
    Devise: 'Dollar ÉTATS-UNIS',
    Code: 'USD',
    Numéro: 840
  },
  {
    Pays: 'MOLDOVIE (LA RÉPUBLIQUE DE)',
    Devise: 'Leu Moldavien',
    Code: 'MDL',
    Numéro: 498
  },
  {
    Pays: 'MONACO',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'MONGOLIE',
    Devise: 'Tugrik',
    Code: 'MNT',
    Numéro: 496
  },
  {
    Pays: 'MONTENEGRO',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'MONTSERRAT',
    Devise: 'Dollar des Caraïbes orientales',
    Code: 'XCD',
    Numéro: 951
  },
  {
    Pays: 'MOROCCO',
    Devise: 'Dirham Marocain',
    Code: 'MAD',
    Numéro: 504
  },
  {
    Pays: 'MOZAMBIQUE',
    Devise: 'Metical',
    Code: 'MZN',
    Numéro: 943
  },
  {
    Pays: 'MYANMAR',
    Devise: 'Kyat',
    Code: 'MMK',
    Numéro: 104
  },
  {
    Pays: 'NAMIBIE',
    Devise: 'Dollar Namibien',
    Code: 'NAD',
    Numéro: 516
  },
  {
    Pays: 'NAMIBIe',
    Devise: 'Rand',
    Code: 'ZAR',
    Numéro: 710
  },
  {
    Pays: 'NAURU',
    Devise: 'Dollar Australien',
    Code: 'AUD',
    Numéro: 36
  },
  {
    Pays: 'NICARAGUA',
    Devise: 'Cordoba',
    Code: 'NIO',
    Numéro: 558
  },
  {
    Pays: 'NIGER (LE)',
    Devise: 'Franc CFA BCEAO',
    Code: 'XOF',
    Numéro: 952
  },
  {
    Pays: 'NIGERIA',
    Devise: 'Naira',
    Code: 'NGN',
    Numéro: 566
  },
  {
    Pays: 'NIUE',
    Devise: 'Dollar Néo-Zélandais',
    Code: 'NZD',
    Numéro: 554
  },
  {
    Pays: 'NORVÈGE',
    Devise: 'Couronne Norvégienne',
    Code: 'NOK',
    Numéro: 578
  },
  {
    Pays: 'NOUVELLE CALÉDONIE',
    Devise: 'Franc CFP',
    Code: 'XPF',
    Numéro: 953
  },
  {
    Pays: 'NOUVELLE-ZÉLANDE',
    Devise: 'Néo-Zélandais',
    Code: 'NZD',
    Numéro: 554
  },
  {
    Pays: 'NÉPAL',
    Devise: 'Rupee Népalais',
    Code: 'NPR',
    Numéro: 524
  },
  {
    Pays: 'OMAN',
    Devise: 'Rial Omani',
    Code: 'OMR',
    Numéro: 512
  },
  {
    Pays: 'OUGANDA',
    Devise: 'Shilling Ougandaisg',
    Code: 'UGX',
    Numéro: 800
  },
  {
    Pays: 'OUZBEKISTAN',
    Devise: "Sum d'Oubekistan",
    Code: 'UZS',
    Numéro: 860
  },
  {
    Pays: 'PAKISTAN',
    Devise: 'Rupee du Pakistan',
    Code: 'PKR',
    Numéro: 586
  },
  {
    Pays: 'PALAU',
    Devise: 'Dollars ÉTATS-UNIS',
    Code: 'USD',
    Numéro: 840
  },
  {
    Pays: 'PALESTINE, ÉTAT DE',
    Devise: 'Pas de devise universelle',
    Code: '',
    Numéro: null
  },
  {
    Pays: 'PANAMA',
    Devise: 'Balboa',
    Code: 'PAB',
    Numéro: 590
  },
  {
    Pays: 'PANAMA',
    Devise: 'Dollars ÉTATS-UNIS',
    Code: 'USD',
    Numéro: 840
  },
  {
    Pays: 'PAPOUASIE NOUVELLE GUINÉE',
    Devise: 'Kina',
    Code: 'PGK',
    Numéro: 598
  },
  {
    Pays: 'PARAGUAY',
    Devise: 'Guarani',
    Code: 'PYG',
    Numéro: 600
  },
  {
    Pays: 'PAYS BAS (LES)',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'PAYS MEMBRES DU GROUPE DE LA BANQUE AFRICAINE DE DEVELOPPEMENT',
    Devise: 'Unité de compte de la BAD',
    Code: 'XUA',
    Numéro: 965
  },
  {
    Pays: 'PAYS-BAS CARIBÉENS',
    Devise: 'Dollar ÉTATS-UNIS',
    Code: 'USD',
    Numéro: 840
  },
  {
    Pays: 'PEROU',
    Devise: 'Nouveau Sol',
    Code: 'PEN',
    Numéro: 604
  },
  {
    Pays: 'PHILIPPINES (LES)',
    Devise: 'Peso Phillipins',
    Code: 'PHP',
    Numéro: 608
  },
  {
    Pays: 'PITCAIRN',
    Devise: 'Dollar Néo-Zélandais',
    Code: 'NZD',
    Numéro: 554
  },
  {
    Pays: 'POLOGNE',
    Devise: 'Zloty',
    Code: 'PLN',
    Numéro: 985
  },
  {
    Pays: 'POLYNÉSIE FRANÇAISE',
    Devise: 'Franc CFP',
    Code: 'XPF',
    Numéro: 953
  },
  {
    Pays: 'PORTO RICO',
    Devise: 'Dollars ÉTATS-UNIS',
    Code: 'USD',
    Numéro: 840
  },
  {
    Pays: 'PORTUGAL',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'QATAR',
    Devise: 'Rial Qatari',
    Code: 'QAR',
    Numéro: 634
  },
  {
    Pays: 'ROUMANIE',
    Devise: 'Leu Roumain',
    Code: 'RON',
    Numéro: 946
  },
  {
    Pays: "ROYAUME-UNI DE GRANDE-BRETAGNE ET D'IRLANDE DU NORD (LE)",
    Devise: 'Livre Sterling',
    Code: 'GBP',
    Numéro: 826
  },
  {
    Pays: 'RUSSIE FÉDÉRATION (LA)',
    Devise: 'Ruble Russe',
    Code: 'RUB',
    Numéro: 643
  },
  {
    Pays: 'RWANDA',
    Devise: 'Franc Rwandais',
    Code: 'RWF',
    Numéro: 646
  },
  {
    Pays: 'RÉPUBLIQUE DOMINICAINE (LA)',
    Devise: 'Peso Dominicain',
    Code: 'DOP',
    Numéro: 214
  },
  {
    Pays: 'RÉPUBLIQUE TCHÈQUE (LA)',
    Devise: 'Couronne Tchèque',
    Code: 'CZK',
    Numéro: 203
  },
  {
    Pays: 'RÉUNION',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'SAHARA OCCIDENTAL',
    Devise: 'Dirham Maroccain',
    Code: 'MAD',
    Numéro: 504
  },
  {
    Pays: 'SAINT BARTHÉLEMY',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'SAINT KITTS AND NEVIS',
    Devise: 'Dollar des Caraïbes orientales',
    Code: 'XCD',
    Numéro: 951
  },
  {
    Pays: 'SAINT LUCIE',
    Devise: 'Dollar des Caraïbes orientales',
    Code: 'XCD',
    Numéro: 951
  },
  {
    Pays: 'SAINT MARIN',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'SAINT MARTIN (PARTIE FRANÇAISE)',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'SAINT PIERRE ET MIQUELON',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'SAINT SIÈGE(LE)',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'SAINT-VINCENT-ET-LES-GRENADINES',
    Devise: 'Dollar des Caraïbes orientales',
    Code: 'XCD',
    Numéro: 951
  },
  {
    Pays: 'Sainte-Hélène, Ascension et Tristan da CunhaA',
    Devise: 'Livre de Saint Helene',
    Code: 'SHP',
    Numéro: 654
  },
  {
    Pays: 'SAMOA',
    Devise: 'Tala',
    Code: 'WST',
    Numéro: 882
  },
  {
    Pays: 'SAMOA AMÉRICAINE',
    Devise: 'Dollars ÉTATS-UNIS',
    Code: 'USD',
    Numéro: 840
  },
  {
    Pays: 'SAO TOME ET PRINCIPE',
    Devise: 'Dobra',
    Code: 'STD',
    Numéro: 678
  },
  {
    Pays: 'SERBIE',
    Devise: 'Dinar Serbe',
    Code: 'RSD',
    Numéro: 941
  },
  {
    Pays: 'SEYCHELLES',
    Devise: 'Rupee des Seychelles',
    Code: 'SCR',
    Numéro: 690
  },
  {
    Pays: 'SIERRA LEONE',
    Devise: 'Leone',
    Code: 'SLL',
    Numéro: 694
  },
  {
    Pays: 'SINGAPOUR',
    Devise: 'Dollar Singaporien',
    Code: 'SGD',
    Numéro: 702
  },
  {
    Pays: 'SINT MAARTEN (PARTIE NEERLANDAISE)',
    Devise: 'Florin des Antilles néerlandaises',
    Code: 'ANG',
    Numéro: 532
  },
  {
    Pays: 'SISTEMA UNITARIO DE COMPENSACION REGIONAL DE PAGOS "SUCRE"',
    Devise: 'Sucre',
    Code: 'XSU',
    Numéro: 994
  },
  {
    Pays: 'SLOVAQUIE',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'SLOVÉNIE',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'SOLOMON ILES S',
    Devise: 'Dollar des îles Solomon',
    Code: 'SBD',
    Numéro: 90
  },
  {
    Pays: 'SOMALIE',
    Devise: 'Shilling Somalien',
    Code: 'SOS',
    Numéro: 706
  },
  {
    Pays: 'SOUDAN (LE)',
    Devise: 'Livre Soudanais',
    Code: 'SDG',
    Numéro: 938
  },
  {
    Pays: 'SOUDAN DU SUD',
    Devise: 'Livre sud-soudanaise',
    Code: 'SSP',
    Numéro: 728
  },
  {
    Pays: 'SRI LANKA',
    Devise: 'Rupee Sri Lankais',
    Code: 'LKR',
    Numéro: 144
  },
  {
    Pays: 'SUISSE',
    Devise: 'WIR Euro',
    Code: 'CHE',
    Numéro: 947
  },
  {
    Pays: 'SUISSE',
    Devise: 'Franc Suisse',
    Code: 'CHF',
    Numéro: 756
  },
  {
    Pays: 'SUISSE',
    Devise: 'Franc WIR',
    Code: 'CHW',
    Numéro: 948
  },
  {
    Pays: 'SURINAME',
    Devise: 'Dollars du Surinam',
    Code: 'SRD',
    Numéro: 968
  },
  {
    Pays: 'SUÈDE',
    Devise: 'Couronne Suédoise',
    Code: 'SEK',
    Numéro: 752
  },
  {
    Pays: 'SVALBARD ET JAN MAYEN',
    Devise: 'Couronne Norvégienne',
    Code: 'NOK',
    Numéro: 578
  },
  {
    Pays: 'SWAZILAND',
    Devise: 'Lilangeni',
    Code: 'SZL',
    Numéro: 748
  },
  {
    Pays: 'SYRIE, REPUBLIQUE ARABE DE',
    Devise: 'Pound Syrien',
    Code: 'SYP',
    Numéro: 760
  },
  {
    Pays: 'SéNéGAL',
    Devise: 'Franc CFA BCEAO',
    Code: 'XOF',
    Numéro: 952
  },
  {
    Pays: 'TADJIKISTAN',
    Devise: 'Somoni',
    Code: 'TJS',
    Numéro: 972
  },
  {
    Pays: 'TAIWAN (PROVINCE DE CHINE)',
    Devise: 'Nouveau dollars Taiwanais',
    Code: 'TWD',
    Numéro: 901
  },
  {
    Pays: 'TANZANIE, RÉPUBLIQUE UNIE DE',
    Devise: 'Shilling Tanzanien',
    Code: 'TZS',
    Numéro: 834
  },
  {
    Pays: 'TCHAD',
    Devise: 'CFA Franc BEAC',
    Code: 'XAF',
    Numéro: 950
  },
  {
    Pays: "TERRITOIRES ANGLAIS DE L'OCÉAN INDIEN (LES)",
    Devise: 'US Dollar',
    Code: 'USD',
    Numéro: 840
  },
  {
    Pays: 'TERRITOIRES DU SUD FRANÇAIS (LES)',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'THAILANDE',
    Devise: 'Baht',
    Code: 'THB',
    Numéro: 764
  },
  {
    Pays: 'TIMOR-LESTE',
    Devise: 'Dollars ÉTATS-UNIS',
    Code: 'USD',
    Numéro: 840
  },
  {
    Pays: 'TOGO',
    Devise: 'Franc CFA BCEAO',
    Code: 'XOF',
    Numéro: 952
  },
  {
    Pays: 'TOKELAU',
    Devise: 'Dollar néo-zélandais',
    Code: 'NZD',
    Numéro: 554
  },
  {
    Pays: 'TONGA',
    Devise: 'Pa’anga',
    Code: 'TOP',
    Numéro: 776
  },
  {
    Pays: 'TRINIDAD et TOBAGO',
    Devise: 'Dollars de Trinidad et Tobago',
    Code: 'TTD',
    Numéro: 780
  },
  {
    Pays: 'TUNISIA',
    Devise: 'Dinars Tunisiens',
    Code: 'TND',
    Numéro: 788
  },
  {
    Pays: 'TURKMENISTAN',
    Devise: 'Turkménistan Nouveau Manat',
    Code: 'TMT',
    Numéro: 934
  },
  {
    Pays: 'TURQUIE',
    Devise: 'Livre Turque',
    Code: 'TRY',
    Numéro: 949
  },
  {
    Pays: 'TUVALU',
    Devise: 'Dollars Australiens',
    Code: 'AUD',
    Numéro: 36
  },
  {
    Pays: 'UKRAINE',
    Devise: 'Hryvnia',
    Code: 'UAH',
    Numéro: 980
  },
  {
    Pays: 'UNION EUROPÉENNE',
    Devise: 'Euro',
    Code: 'EUR',
    Numéro: 978
  },
  {
    Pays: 'UNITED ARAB EMIRATES (THE)',
    Devise: 'Dirham UAE',
    Code: 'AED',
    Numéro: 784
  },
  {
    Pays: 'URUGUAY',
    Devise: 'Uruguay Peso en Unidades Indexadas (URUIURUI)',
    Code: 'UYI',
    Numéro: 940
  },
  {
    Pays: 'URUGUAY',
    Devise: 'Peso Uruguayen',
    Code: 'UYU',
    Numéro: 858
  },
  {
    Pays: 'VANUATU',
    Devise: 'Vatu',
    Code: 'VUV',
    Numéro: 548
  },
  {
    Pays: 'VENEZUELA (RÉPUBLIQUE DE BOLIVARIE)',
    Devise: 'Bolivar',
    Code: 'VEF',
    Numéro: 937
  },
  {
    Pays: 'VIETNAM',
    Devise: 'Dong',
    Code: 'VND',
    Numéro: 704
  },
  {
    Pays: 'WALLIS ET FUTUNA',
    Devise: 'Franc CFP',
    Code: 'XPF',
    Numéro: 953
  },
  {
    Pays: 'YEMEN',
    Devise: 'Rial du Yemenl',
    Code: 'YER',
    Numéro: 886
  },
  {
    Pays: 'ZAMBIE',
    Devise: 'Kwacha Zambien',
    Code: 'ZMW',
    Numéro: 967
  },
  {
    Pays: 'ZIMBABWE',
    Devise: 'Dollars du Zimbabwe',
    Code: 'ZWL',
    Numéro: 932
  },
  {
    Pays: "ÉTATS-UNIS D'AMÉRIQUE (LES)",
    Devise: 'Dollars ÉTATS-UNIS',
    Code: 'USD',
    Numéro: 840
  },
  {
    Pays: "ÉTATS-UNIS D'AMÉRIQUE (Les)",
    Devise: 'Dollars ÉTATS-UNIS (Prochain jours)',
    Code: 'USN',
    Numéro: 997
  },
  {
    Pays: 'ÎLE CHRISTMAS',
    Devise: 'Dollar Australien',
    Code: 'AUD',
    Numéro: 36
  },
  {
    Pays: "ÎLE D'HEARD ET ÎLES McDONALD",
    Devise: 'Dollar Australien',
    Code: 'AUD',
    Numéro: 36
  },
  {
    Pays: 'ÎLES MINEURES ÉLOIGNÉES DES ÉTATS-UNIS (LES)',
    Devise: 'Dollars ÉTATS-UNIS',
    Code: 'USD',
    Numéro: 840
  },
  {
    Pays: 'ÎLES TURQUES-ET-CAÏQUES (LES)',
    Devise: 'Dollar ÉTATS-UNIS',
    Code: 'USD',
    Numéro: 840
  },
  {
    Pays: 'ÎLES VIERGES (U.S.)',
    Devise: 'Dollar ÉTATS-UNIS',
    Code: 'USD',
    Numéro: 840
  }
]

var languageNamesFR = [
  {
    French: 'Afar',
    English: 'Afar'
  },
  {
    French: 'Abkhaze',
    English: 'Abkhazian'
  },
  {
    French: 'Avestique',
    English: 'Avestan'
  },
  {
    French: 'Afrikaans',
    English: 'Afrikaans'
  },
  {
    French: 'Akan',
    English: 'Akan'
  },
  {
    French: 'Amharique',
    English: 'Amharic'
  },
  {
    French: 'Aragonais',
    English: 'Aragonese'
  },
  {
    French: 'Arabe',
    English: 'Arabic'
  },
  {
    French: 'Assamais',
    English: 'Assamese'
  },
  {
    French: 'Avar',
    English: 'Avaric'
  },
  {
    French: 'Aymara',
    English: 'Aymara'
  },
  {
    French: 'Azéri',
    English: 'Azerbaijani'
  },
  {
    French: 'Bachkir',
    English: 'Bashkir'
  },
  {
    French: 'Biélorusse',
    English: 'Belarusian'
  },
  {
    French: 'Bulgare',
    English: 'Bulgarian'
  },
  {
    French: 'Bihari',
    English: 'Bihari'
  },
  {
    French: 'Bichelamar',
    English: 'Bislama'
  },
  {
    French: 'Bambara',
    English: 'Bambara'
  },
  {
    French: 'Bengali',
    English: 'Bengali'
  },
  {
    French: 'Tibétain',
    English: 'Tibetan'
  },
  {
    French: 'Breton',
    English: 'Breton'
  },
  {
    French: 'Bosnien',
    English: 'Bosnian'
  },
  {
    French: 'Catalan',
    English: 'Catalan'
  },
  {
    French: 'Tchétchène',
    English: 'Chechen'
  },
  {
    French: 'Chamorro',
    English: 'Chamorro'
  },
  {
    French: 'Corse',
    English: 'Corsican'
  },
  {
    French: 'Cri',
    English: 'Cree'
  },
  {
    French: 'Tchèque',
    English: 'Czech'
  },
  {
    French: 'Vieux-slave',
    English: 'Old Church Slavonic'
  },
  {
    French: 'Tchouvache',
    English: 'Chuvash'
  },
  {
    French: 'Gallois',
    English: 'Welsh'
  },
  {
    French: 'Danois',
    English: 'Danish'
  },
  {
    French: 'Allemand',
    English: 'German'
  },
  {
    French: 'Maldivien',
    English: 'Divehi'
  },
  {
    French: 'Dzongkha',
    English: 'Dzongkha'
  },
  {
    French: 'Ewe',
    English: 'Ewe'
  },
  {
    French: 'Grec moderne',
    English: 'Greek'
  },
  {
    French: 'Anglais',
    English: 'English'
  },
  {
    French: 'Espéranto',
    English: 'Esperanto'
  },
  {
    French: 'Espagnol',
    English: 'Spanish'
  },
  {
    French: 'Estonien',
    English: 'Estonian'
  },
  {
    French: 'Basque',
    English: 'Basque'
  },
  {
    French: 'Persan',
    English: 'Persian'
  },
  {
    French: 'Peul',
    English: 'Fulah'
  },
  {
    French: 'Finnois',
    English: 'Finnish'
  },
  {
    French: 'Fidjien',
    English: 'Fijian'
  },
  {
    French: 'Féroïen',
    English: 'Faroese'
  },
  {
    French: 'Français',
    English: 'French'
  },
  {
    French: 'Frison occidental',
    English: 'Western Frisian'
  },
  {
    French: 'Irlandais',
    English: 'Irish'
  },
  {
    French: 'Écossais',
    English: 'Scottish Gaelic'
  },
  {
    French: 'Galicien',
    English: 'Galician'
  },
  {
    French: 'Guarani',
    English: 'Guarani'
  },
  {
    French: 'Gujarati',
    English: 'Gujarati'
  },
  {
    French: 'Mannois',
    English: 'Manx'
  },
  {
    French: 'Haoussa',
    English: 'Hausa'
  },
  {
    French: 'Hébreu',
    English: 'Hebrew'
  },
  {
    French: 'Hébreu',
    English: 'Hebrew (modern)'
  },
  {
    French: 'Hindi',
    English: 'Hindi'
  },
  {
    French: 'Hiri motu',
    English: 'Hiri Motu'
  },
  {
    French: 'Croate',
    English: 'Croatian'
  },
  {
    French: 'Créole haïtien',
    English: 'Haitian'
  },
  {
    French: 'Hongrois',
    English: 'Hungarian'
  },
  {
    French: 'Arménien',
    English: 'Armenian'
  },
  {
    French: 'Héréro',
    English: 'Herero'
  },
  {
    French: 'Interlingua',
    English: 'Interlingua'
  },
  {
    French: 'Indonésien',
    English: 'Indonesian'
  },
  {
    French: 'Occidental',
    English: 'Interlingue'
  },
  {
    French: 'Igbo',
    English: 'Igbo'
  },
  {
    French: 'Yi',
    English: 'Sichuan Yi'
  },
  {
    French: 'Inupiak',
    English: 'Inupiaq'
  },
  {
    French: 'Ido',
    English: 'Ido'
  },
  {
    French: 'Islandais',
    English: 'Icelandic'
  },
  {
    French: 'Italien',
    English: 'Italian'
  },
  {
    French: 'Inuktitut',
    English: 'Inuktitut'
  },
  {
    French: 'Japonais',
    English: 'Japanese'
  },
  {
    French: 'Javanais',
    English: 'Javanese'
  },
  {
    French: 'Géorgien',
    English: 'Georgian'
  },
  {
    French: 'Kikongo',
    English: 'Kongo'
  },
  {
    French: 'Kikuyu',
    English: 'Kikuyu'
  },
  {
    French: 'Kuanyama',
    English: 'Kwanyama'
  },
  {
    French: 'Kazakh',
    English: 'Kazakh'
  },
  {
    French: 'Groenlandais',
    English: 'Kalaallisut'
  },
  {
    French: 'Khmer',
    English: 'Khmer'
  },
  {
    French: 'Kannada',
    English: 'Kannada'
  },
  {
    French: 'Coréen',
    English: 'Korean'
  },
  {
    French: 'Kanouri',
    English: 'Kanuri'
  },
  {
    French: 'Cachemiri',
    English: 'Kashmiri'
  },
  {
    French: 'Kurde',
    English: 'Kurdish'
  },
  {
    French: 'Komi',
    English: 'Komi'
  },
  {
    French: 'Cornique',
    English: 'Cornish'
  },
  {
    French: 'Kirghiz',
    English: 'Kirghiz'
  },
  {
    French: 'Latin',
    English: 'Latin'
  },
  {
    French: 'Luxembourgeois',
    English: 'Luxembourgish'
  },
  {
    French: 'Ganda',
    English: 'Ganda'
  },
  {
    French: 'Limbourgeois',
    English: 'Limburgish'
  },
  {
    French: 'Lingala',
    English: 'Lingala'
  },
  {
    French: 'Lao',
    English: 'Lao'
  },
  {
    French: 'Lituanien',
    English: 'Lithuanian'
  },
  {
    French: 'Luba',
    English: 'Luba'
  },
  {
    French: 'Letton',
    English: 'Latvian'
  },
  {
    French: 'Malgache',
    English: 'Malagasy'
  },
  {
    French: 'Marshallais',
    English: 'Marshallese'
  },
  {
    French: 'Maori de Nouvelle-Zélande',
    English: 'M?ori'
  },
  {
    French: 'Macédonien',
    English: 'Macedonian'
  },
  {
    French: 'Malayalam',
    English: 'Malayalam'
  },
  {
    French: 'Mongol',
    English: 'Mongolian'
  },
  {
    French: 'Moldave',
    English: 'Moldavian'
  },
  {
    French: 'Marathi',
    English: 'Marathi'
  },
  {
    French: 'Malais',
    English: 'Malay'
  },
  {
    French: 'Maltais',
    English: 'Maltese'
  },
  {
    French: 'Birman',
    English: 'Burmese'
  },
  {
    French: 'Nauruan',
    English: 'Nauru'
  },
  {
    French: 'Norvégien Bokmål',
    English: 'Norwegian Bokmål'
  },
  {
    French: 'Sindebele',
    English: 'North Ndebele'
  },
  {
    French: 'Népalais',
    English: 'Nepali'
  },
  {
    French: 'Ndonga',
    English: 'Ndonga'
  },
  {
    French: 'Néerlandais',
    English: 'Dutch'
  },
  {
    French: 'Norvégien Nynorsk',
    English: 'Norwegian Nynorsk'
  },
  {
    French: 'Norvégien',
    English: 'Norwegian'
  },
  {
    French: 'Nrebele',
    English: 'South Ndebele'
  },
  {
    French: 'Navajo',
    English: 'Navajo'
  },
  {
    French: 'Chichewa',
    English: 'Chichewa'
  },
  {
    French: 'Occitan',
    English: 'Occitan'
  },
  {
    French: 'Ojibwé',
    English: 'Ojibwa'
  },
  {
    French: 'Oromo',
    English: 'Oromo'
  },
  {
    French: 'Oriya',
    English: 'Oriya'
  },
  {
    French: 'Ossète',
    English: 'Ossetian'
  },
  {
    French: 'Pendjabi',
    English: 'Panjabi'
  },
  {
    French: 'Pali',
    English: 'P?li'
  },
  {
    French: 'Polonais',
    English: 'Polish'
  },
  {
    French: 'Pachto',
    English: 'Pashto'
  },
  {
    French: 'Portugais',
    English: 'Portuguese'
  },
  {
    French: 'Quechua',
    English: 'Quechua'
  },
  {
    French: 'Créole Réunionnais',
    English: 'Reunionese'
  },
  {
    French: 'Romanche',
    English: 'Romansh'
  },
  {
    French: 'Kirundi',
    English: 'Kirundi'
  },
  {
    French: 'Roumain',
    English: 'Romanian'
  },
  {
    French: 'Russe',
    English: 'Russian'
  },
  {
    French: 'Kinyarwanda',
    English: 'Kinyarwanda'
  },
  {
    French: 'Sanskrit',
    English: 'Sanskrit'
  },
  {
    French: 'Sarde',
    English: 'Sardinian'
  },
  {
    French: 'Sindhi',
    English: 'Sindhi'
  },
  {
    French: 'Same du Nord',
    English: 'Northern Sami'
  },
  {
    French: 'Sango',
    English: 'Sango'
  },
  {
    French: 'Serbo-croate',
    English: 'Serbo-Croatian'
  },
  {
    French: 'Cingalais',
    English: 'Sinhalese'
  },
  {
    French: 'Slovaque',
    English: 'Slovak'
  },
  {
    French: 'Slovène',
    English: 'Slovenian'
  },
  {
    French: 'Samoan',
    English: 'Samoan'
  },
  {
    French: 'Shona',
    English: 'Shona'
  },
  {
    French: 'Somali',
    English: 'Somali'
  },
  {
    French: 'Albanais',
    English: 'Albanian'
  },
  {
    French: 'Serbe',
    English: 'Serbian'
  },
  {
    French: 'Swati',
    English: 'Swati'
  },
  {
    French: 'Sotho du Sud',
    English: 'Sotho'
  },
  {
    French: 'Soundanais',
    English: 'Sundanese'
  },
  {
    French: 'Suédois',
    English: 'Swedish'
  },
  {
    French: 'Swahili',
    English: 'Swahili'
  },
  {
    French: 'Tamoul',
    English: 'Tamil'
  },
  {
    French: 'Télougou',
    English: 'Telugu'
  },
  {
    French: 'Tadjik',
    English: 'Tajik'
  },
  {
    French: 'Thaï',
    English: 'Thai'
  },
  {
    French: 'Tigrigna',
    English: 'Tigrinya'
  },
  {
    French: 'Turkmène',
    English: 'Turkmen'
  },
  {
    French: 'Tagalog',
    English: 'Tagalog'
  },
  {
    French: 'Tswana',
    English: 'Tswana'
  },
  {
    French: 'Tongien',
    English: 'Tonga'
  },
  {
    French: 'Turc',
    English: 'Turkish'
  },
  {
    French: 'Tsonga',
    English: 'Tsonga'
  },
  {
    French: 'Tatar',
    English: 'Tatar'
  },
  {
    French: 'Twi',
    English: 'Twi'
  },
  {
    French: 'Tahitien',
    English: 'Tahitian'
  },
  {
    French: 'Ouïghour',
    English: 'Uighur'
  },
  {
    French: 'Ukrainien',
    English: 'Ukrainian'
  },
  {
    French: 'Ourdou',
    English: 'Urdu'
  },
  {
    French: 'Ouzbek',
    English: 'Uzbek'
  },
  {
    French: 'Venda',
    English: 'Venda'
  },
  {
    French: 'Vietnamien',
    English: 'Viêt Namese'
  },
  {
    French: 'Volapük',
    English: 'Volapük'
  },
  {
    French: 'Wallon',
    English: 'Walloon'
  },
  {
    French: 'Wolof',
    English: 'Wolof'
  },
  {
    French: 'Xhosa',
    English: 'Xhosa'
  },
  {
    French: 'Yiddish',
    English: 'Yiddish'
  },
  {
    French: 'Yoruba',
    English: 'Yoruba'
  },
  {
    French: 'Zhuang',
    English: 'Zhuang'
  },
  {
    French: 'Chinois',
    English: 'Chinese'
  },
  {
    French: 'Zoulou',
    English: 'Zulu'
  }
]

const FrenchTranslations = data => {
  let currencyName
  let languageName

  for (let i = 0; i < currencyNamesFR.length; i++) {
    if (
      currencyNamesFR[i].Code.toLowerCase() === data.currencyCode.toLowerCase()
    ) {
      currencyName = currencyNamesFR[i].Devise.toLowerCase()
      break
    } else {
      currencyName = undefined
    }
  }

  languageName = data.language.map(lang => {
    let returnstr
    for (let i = 0; i < languageNamesFR.length; i++) {
      if (
        languageNamesFR[i].English.toLowerCase() === lang.name.toLowerCase()
      ) {
        returnstr = languageNamesFR[i].French.toLowerCase()
        break
      } else {
        returnstr = undefined
      }
    }
    return returnstr
  })

  return [currencyName, languageName]
}

export default FrenchTranslations
