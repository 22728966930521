import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Navigation from './components/Navigation'
import Footer from './components/Footer'

import CityGuidePoster from './components/CityGuide/CityGuidePoster'
import CityGuideIntro from './components/CityGuide/CityGuideIntro'
import CityGuideLinks from './components/CityGuide/CityGuideLinks'
import MapModule from './components/CityGuide/MapModule'
import HotelsCarousel from './components/CityGuide/HotelsCarousel'
import AirCanadaLinks from './components/CityGuide/AirCanadaLinks'
import CityGuides from './components/CityGuide/CityGuides'
import StickyNav from './components/CityGuide/StickyNav'
import MultipleEntry from './components/CityGuide/MultipleEntry'
import Ads from './components/Ads'
import Fade from 'react-reveal/Fade'
import { sContext } from '../context'
import TopCityGuide from './components/CityGuide/TopCityGuide'

const CityGuide = props => {
  const [markers, setMarkers] = useState([])
  const [triggerMore, setTriggerMore] = useState(false)

  const skipFade = []

  useEffect(() => {
    const markersArray = []
    const sections = props.pages.sections || props.pages.content.content.filter(v => v.nodeType === 'embedded-entry-block').map(v => v.data.target)

    sections.forEach(section => {
      if(section) {
        const {
          fields: field,
          fields: {
            subSections
          }
        } = section
  
        const lowerText = field.text && field.text.toLowerCase()
        if (
          lowerText === 'where to stay' ||
          lowerText === 'eat & drink' ||
          lowerText === 'what to do'
        ) {
          if (subSections) {
            subSections.forEach(({ fields: data }) => {
              if (data.directionsLink) {
                let location = data.location || null
                if (!location) {
                  const parsedLocation = data.directionsLink
                    .split('/@')
                    .pop()
                    .split('/')
                    .shift()
                    .split(',')
                  location = {
                    lon: Number(parsedLocation[1]),
                    lat: Number(parsedLocation[0])
                  }
                }
  
                markersArray.push({
                  fields: {
                    title: data.title,
                    markerText: data.title,
                    directionsLink: data.directionsLink,
                    location
                  }
                })
              }
            })
          }
        }
      }
    })

    setMarkers(markersArray)
  }, [props])

  const moreTriggered = () => {
    setTriggerMore(prevState => (!prevState))
  }

  const sections = props.pages.sections || props.pages.content.content.filter(v => v.nodeType === 'embedded-entry-block').map(v => v.data.target)
  
  return (
    <>
      <Helmet />
      <div id="cityguide" className="container-fluid">
        <div className="top-ad-wrapper">
          <Ads
            page='homepage'
            counter={1} />
        </div>
        <div className="main-wrapper">
        <Navigation
          navigationColor={props.pages.navigationColor}
          default={props.pages.navigationDefaultColor}
          menu={props.navigation}
          paths={props.paths || {}} />
          <main>
            {sections
              .map((data, index) => {
                if(!data) return null

                let module = data.sys.contentType.sys.id === 'pageSection' ? data.fields.type : data.sys.contentType.sys.id

                  const {
                    fields: {
                      aggregatorTag,
                      credit,
                      text,
                      image,
                      subSections,
                      mapColour,
                      mapMarkers,
                      mapZoom,
                      mapCenter,
                      airCanadaLinkEdition,
                      airCanadaLinkOrig,
                      airCanadaLinkDest,
                      airCanadaLinkTc,
                      imageTitle,
                      inArticleAsset,
                      cityGuidesCode
                    },
                    sys: {
                      id
                    }
                  } = data
                switch (module) {
                  case 'ArticleTop':
                    skipFade.push(index)
                    return (
                      <React.Fragment key={'module-' + index}>
                        <TopCityGuide
                          imageTitle={imageTitle}
                          inArticleAsset={inArticleAsset}
                          cityGuidesCode={cityGuidesCode}
                        />
                      </React.Fragment>
                    )

                  case 'cityguide-poster':
                    skipFade.push(index)
                    return (
                      <React.Fragment key={'module-' + index}>
                        <CityGuidePoster
                          city={credit}
                          text={text}
                          image={image}
                        />
                      </React.Fragment>
                    )

                  case 'cityguide-intro':
                    return (
                      <CityGuideIntro
                        key={'module-' + index}
                        text={text}
                        sponsor={image}
                      />
                    )

                  case 'cityguide-links':
                    return (
                      <CityGuideLinks
                        key={'module-' + index}
                        id={id}
                        cityguides={subSections}
                        text={text}
                        color={aggregatorTag?.[0] ?? []}
                        moreTriggered={moreTriggered} />
                    )

                  case 'mapSection':
                    return (
                      <MapModule
                        key={'module-' + index}
                        mapColour={mapColour}
                        mapMarkers={mapMarkers}
                        mapZoom={mapZoom}
                        mapCenter={mapCenter}
                      />
                    )

                  case 'cityguide-multiple-entry':
                    return (
                      <div
                        key={'module-' + index}
                        className="relative" >
                        <MultipleEntry
                          text={text}
                          subSections={subSections}
                          markers={markers}
                        />
                      </div>
                    )

                  case 'cityguide-hotels-carousel':
                    return (
                      <React.Fragment key={'module-' + index}>
                        <HotelsCarousel
                          text={text}
                          subSections={subSections}
                        />
                        <Ads counter={2} />
                      </React.Fragment>
                    )

                  case 'cityguide-air-canada':
                    return (
                      <React.Fragment key={'module-' + index}>
                        <Ads counter={3} />
                        <AirCanadaLinks
                          text={text}
                          edition={airCanadaLinkEdition}
                          orig={airCanadaLinkOrig}
                          dest={airCanadaLinkDest}
                          tc={airCanadaLinkTc}
                        />
                      </React.Fragment>
                    )

                  case 'cityguide-city-guides':
                    return (
                      <React.Fragment key={'module-' + index}>
                        <CityGuides
                          text={text}
                          cityguides={subSections}
                          image={image}
                        />
                        <Ads counter={4} />
                      </React.Fragment>
                    )

                  case 'cityguide-sticky-nav':
                    skipFade.push(index)
                    return (
                      <StickyNav
                        key={'module-' + index}
                        externalLinks={subSections}
                        triggerMore={triggerMore} />
                    )

                  default:
                    return null
                }
              })
              .map((c, i) => {
                if (c && skipFade.indexOf(i) >= 0) {
                  return <div key={`fade-${i}`}>{c}</div>
                } else if (c) {
                  return <Fade key={`fade-${i}`}>{c}</Fade>
                }
                return c
              })}

            {/* Footer */}
            <Footer
              footer={props.footer}
              location={props.location} />
          </main>
        </div>
      </div>
    </>
  )
}

export default CityGuide
