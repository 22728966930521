import React, { useContext } from 'react'
import LazyLoadImage from '../LazyLoadImage'
import { LinkRenderer } from '../../../lib/utils'
import { sContext } from '../../../context'

import ReactMarkdown from '../../../lib/ReactMarkdown'

const CityGuideLinks = ({
  color,
  text,
  id,
  cityguides,
  moreTriggered
}) => {
  const localePath = useContext(sContext).currentLocale

  const showList = (id) => {
    const containerElement = document.getElementById(`links-container-${id}`)
    const moreElement = document.getElementById(`links-more-${id}`)
    containerElement.style.maxHeight = '2500vh'
    moreElement.style.display = 'none'
    moreTriggered()
  }

  return (
    <div
      id={color === 'Black' || color === 'black' ?
        'title_3' : 'title_4'}
      className={`row links-main ${color || ''}`}
      data-title={text} >
      <div className="col-lg-12">
        <div className="links-container" id={'links-container-' + id}>
          <div className="row links-line">
            <div className="col-lg-1 links-line-left"></div>
            <div className="col-lg-1 links-line-right"></div>
          </div>
          <h2 className="row links-title">
            {' '}
            {text}{' '}
          </h2>
          {cityguides.map((data, i) => (
            <div
              key={`City-Links-${text}-${i}`}
              className={
                'row links-guides ' + (data.fields.type ? data.fields.type : '')
              }
            >
              {data.fields.image && (
                <div className="col-xl-4 col-lg-12 links-image">
                  <LazyLoadImage
                    asset={data.fields.image.fields}
                    className="links-image-file"
                    sizes={[{ viewport: 0, width: 400 }]} />
                </div>
              )}
              <div className={`
                links-text
                ${data.fields.image ? '' : 'no-image'}
                ${data.fields.image ? 'col-xl-6 col-lg-9' : 'col-lg-9 col-xl-10'}
              `}>
                {data.fields.type && (
                  <h4 className="links-type">{data.fields.type}</h4>
                )}
  
                <ReactMarkdown
                  escapeHtml={false}
                  source={'__' + data.fields.title + '__ ' + data.fields.text}
                  renderers={{ link: LinkRenderer }}
                />
              </div>
              <div className="col-lg-3 col-xl-2 links-links">
                {data.fields.websiteLink && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={data.fields.websiteLink}
                  >
                    {localePath === 'en' ? 'Website' : 'Site Web'}
                  </a>
                )}
                {data.fields.directionsLink && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={data.fields.directionsLink}
                  >
                    {localePath === 'en' ? 'Directions' : 'Pour S’orienter'}
                  </a>
                )}
                {data.fields.instagramLink && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={data.fields.instagramLink}
                  >
                    Instagram
                  </a>
                )}
                {data.fields.links &&
                  data.fields.links.map((link, index) => (
                    <a
                      key={'links-' + index}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={link.fields.url}
                    >
                      {link.fields.text.split('|')[0]}
                    </a>
                  ))}
              </div>
            </div>
          ))}
        </div>
        <div className="links-more" id={'links-more-' + id}>
          <div id="linksMore" onClick={() => showList(id)}>
            {localePath === 'en' ? 'MORE +' : '+ PLUS'}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CityGuideLinks
