import React, { useContext } from 'react'
import { LinkRenderer } from '../../../lib/utils'
import LazyLoadBgImage from '../../components/LazyLoadBgImage'
import { sContext } from '../../../context'

import ReactMarkdown from '../../../lib/ReactMarkdown'

const CityGuides = element => {
  const localePath = useContext(sContext).currentLocale

  return (
    <div className="row cityguides-guides cityguides-fullwidth">
      <div className="cityguides-lines">
        <div className="cityguides-lines-left"></div>
        <div className="cityguides-lines-right"></div>
      </div>
      <div className="cityguides-whereto">
        <div className="cityguides-title"> {element.text.split('\n')[0]} </div>
        <LazyLoadBgImage
          id="cityguideLinks"
          className="cityguides-link"
          asset={element.image.fields}
          overrides={{
            w: 1000,
            q: 80
          }}
        >
          {element.cityguides
            .sort(function(a, b) {
              return a.fields.title > b.fields.title ? 1 : -1
            })
            .map((data, index) => (
              <div key={'cityguide-link-' + index} className="cityguides-links">
                <a href={'/' + localePath + `/${localePath === 'en' ? 'city-guide' : 'guides-voyage'}/` + data.fields.path + '/'}>
                  {' '}
                  {data.fields.title.split(' - ')[1]}{' '}
                </a>
              </div>
            ))}
        </LazyLoadBgImage>
        <div className="cityguides-title">
          {' '}
          <a style={{ color: 'black' }} href={'/' + localePath + `/${localePath === 'en' ? 'city-guide' : 'guides-voyage'}/`}>
            {' '}
            <ReactMarkdown
              source={element.text.split('\n')[1]}
              escapeHtml={false}
              renderers={{ link: LinkRenderer }}
            />{' '}
          </a>{' '}
        </div>
      </div>
    </div>
  )
}

export default CityGuides
