import React, {
  useEffect,
  useContext,
  useRef,
} from 'react'
import Slider from 'react-slick'
import LazyLoadImage from '../LazyLoadImage'
import { sContext } from '../../../context'
import ReactMarkdown from '../../../lib/ReactMarkdown'

const Carousel = ({ items }) => {
  const slickSlide_Ref = useRef()
  const anchor_Ref = useRef()
  const localePath = useContext(sContext).currentLocale
  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '30px',
    slidesToShow: 3,
    speed: 500,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  useEffect(() => {
    const slickSlide = slickSlide_Ref.current.innerSlider.list
    let slides = slickSlide.children[0].children

    if(slides.length) {
      const buttons = slickSlide.nextSibling.tagName === 'BUTTON' ? [
        slickSlide.nextSibling,
        slickSlide.previousSibling
      ] : []
      
      for (let i = 0; i < slides.length; i++) {
        const slide = slides[i]
        if(slide.classList.contains('slick-cloned')) {
          slide.setAttribute('aria-hidden', true)
          continue;
        }
        
        slide.setAttribute('aria-hidden', false)
        slide.addEventListener('focus', () => {
          for (let i = 0; i < slides.length; i++) {
            const slide = slides[i]
            if(slide.classList.contains('slick-cloned')) {
              continue;
            }
            
            slide.setAttribute('aria-hidden', false)  
            
          }
        })

        const anchor = slide.children[0].children[0]
        anchor.setAttribute('tabindex', '0')
        anchor.addEventListener('focus', () => {
          const index = slide.getAttribute('data-index')
          slickSlide_Ref.current.slickGoTo(index)
        })
      }
  
      for (let i = 0; i < buttons.length; i++) {
        const button = buttons[i];
        button.setAttribute('aria-hidden', true)
        button.setAttribute('tabindex', '-1')
      }
    }
  })

  return (
    typeof window === 'undefined' ? null : 
    <Slider
      ref={slickSlide_Ref}
      {...settings} >
      {items.map((item, i) => {
        try {
          let itemObj = {}
          let isSponsor
          const {
            overrideBanner,
            banner,
            title,
            subtitle,
            type,
            path,
            link,
            referencedContent,
            hasSponsor
          } = item.fields

          isSponsor = hasSponsor || (type && type.includes('Air Canada enRoute'))
          
          const sections = item.fields?.sections || (item.fields.content?.content || []).map(v => v.data?.target).filter(v => v)
          let imageObj = (sections?.length && sections[0]?.fields?.image?.fields) ||
          (overrideBanner
            ? overrideBanner.fields
            : banner?.fields)

          let href
          if(link) {
            href = link

          } else if(referencedContent) {
            href = `/${localePath}/hotels/${referencedContent.fields.path}/`

          } else if(path) {
            href = `/${localePath}/hotels/${path}/`

          } else {
            href = null
          }

          href = href && href.indexOf('/category?') !== -1 ? href.split('/category?').join('/category/?') : href

          itemObj = {
            href,
            imageObj,
            type,
            title,
            subtitle,
            addedAttr: null
          }
  
          return (
            <a
              ref={anchor_Ref}
              href={itemObj.href}
              key={`carousel-item-${i}`}
              className={`
                ${i === 0 ? 'active' : ''}
                ${isSponsor ? 'carousel-item-sponsor' : ''}
              `}
              {...itemObj.addedAttr} >
              <div className="articleCarousel-img">
                  <LazyLoadImage
                    asset={itemObj.imageObj}
                    className="w-100" />
              </div>
              <div className="carousel-content" >
                {itemObj.title &&
                  <h5>
                    <ReactMarkdown renderers={{
                      paragraph: (node) => {
                        return (node && node.children) || null
                      }
                    }} source={itemObj.title} />
                  </h5>
                }
                {itemObj.subtitle &&
                  <span className='carousel-subtitle'>
                    {itemObj.subtitle}
                  </span>
                }
              </div>
            </a>
          )
        } catch (e) {
          console.error(e)
          return null
        }
      }).filter(i => i)}
    </Slider>
  )
}

export default Carousel