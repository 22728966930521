import React from 'react'
import LazyLoadBgImage from '../LazyLoadBgImage'
import { LinkRenderer } from '../../../lib/utils'

import ReactMarkdown from '../../../lib/ReactMarkdown'

const CityGuideIntro = element => (
  <div
    id="title_1"
    className="row intro-main"
    data-title='Intro' >
    <div className="col-lg-12">
      <div
        className="row"
        style={{ visibility: 'hidden' }} >
        Intro
      </div>
      <div className="row intro-text">
        <ReactMarkdown
          source={element.text}
          escapeHtml={false}
          renderers={{ link: LinkRenderer, text: (node) => {
            return `${node.value}`.replace(/-/g,'\u2013')
          } }}
        />
      </div>
      {element.sponsor && (
        <div className="row intro-sponsor">
          <div className="col-lg-2">SUPPORTED BY</div>
          <LazyLoadBgImage
            asset={element.sponsor.fields}
            className="col-lg-2"
          ></LazyLoadBgImage>
        </div>
      )}
    </div>
  </div>
)

export default CityGuideIntro
